/* eslint-disable no-undef */
import React, {useState, useContext, useEffect} from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import BootstrapScreenSize from "../../utils/BootstrapSceensize";
import "./contentSessionCard.css";
import { makeHttpCall } from "../../utils/HttpHelpers";
import MBoxContext from "../../context/MBoxContext";
import SessionEditModal from "../sessionEditModal/SessionEditModal";
import { toast } from "react-toastify";
import MyLink from "../common/MyLink";
import { getBaseUrl } from "../../utils/QueryString";

const ContentSessionCard = (props) => {
  const { startDate, endDate, title, ID, link, onClick, showTime, setIsContentSessionChange, showEdit=false } = props;

  const [contentsessionValue, setContentsessionValue] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);


  const dateFormatNoTime = new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const dateFormatShowTime = new Intl.DateTimeFormat("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });

  const dateFormat = showTime ? dateFormatShowTime : dateFormatNoTime;
  const { width } = useWindowDimensions();
  const { baseUrl, contentSessionSettingsField} = useContext(MBoxContext)
  const { sm, lg } = BootstrapScreenSize;
  const accessToken =  localStorage.getItem("access_token")


  const getContentsessionUrl = `https://${getBaseUrl()}/v1/contentsessions/${ID}.json?access_token=${accessToken}` 


    const getContentsession = async() => {
      const contentsession = await makeHttpCall({
        url: getContentsessionUrl,
        method: "GET",
        isVm: true,
      });

      if(!contentsession?.ok){
        toast(
          contentsession?.error || "Something went wrong!",
          {
            theme: "colored",
            type: "error",
            autoClose: 4000,
          }
        );
        return;
      }


      let result = {isBoolean:{}, isText:{}};
      // if(contentSessionSettingsField.length > 0 ){
        
      //   for(let i=0; i<contentSessionSettingsField.isBoolean.length; i++){
      //     const filterdObject = Object.fromEntries(Object.entries(contentsession.data).filter(([key]) => key.startsWith(contentSessionSettingsField.isBoolean[i])))
      //     result = {...result, isBoolean:{...result.isBoolean,...filterdObject}}
      //   }
      //   for(let i=0; i<contentSessionSettingsField.isText.length; i++){
      //     const filterdObject = Object.fromEntries(Object.entries(contentsession.data).filter(([key]) => key.startsWith(contentSessionSettingsField.isText[i])))
      //     result = {...result, isText:{...result.isText,...filterdObject}}
      //   }
      //   setContentsessionValue(result);
      // }
      if(contentSessionSettingsField.length > 0){
        for(let i = 0; i<contentSessionSettingsField.length; i++){
          const filteredValue = Object.fromEntries(Object.entries(contentsession.data).filter(([key]) => key.startsWith(contentSessionSettingsField[i].title)))

          if(contentSessionSettingsField[i].type === "boolean"){
            result = {...result, isBoolean:{...result.isBoolean,...filteredValue}}
          }else{
            result = {...result, isText:{...result.isText,...filteredValue}}
          }
        }
      }
      setContentsessionValue(result);
      
    }

    const updateContentsession = async() => {
      setIsUpdating(true)
      let newBoolean = {};

      Object.keys(contentsessionValue.isBoolean).forEach((item) => {
        if(contentsessionValue?.isBoolean[item]?.toString() === "true"){
          newBoolean = {...newBoolean, [item]:"1"}
        }else{
          newBoolean = {...newBoolean, [item]:"0"}
        }
      })
      const params = {...newBoolean, ...contentsessionValue.isText}
      let newParams = {}
      for(const key in params){
        const filteredTitle = contentSessionSettingsField.filter(el => el.title === key);
        if(filteredTitle[0]?.editable){
            newParams = {...newParams, [key]:params[key]}
        }
      }
      const update = await makeHttpCall({
        url:getContentsessionUrl,
        method: "PUT",
        bodyJson: JSON.stringify(newParams),
        isVm: true,
      });

      if(update?.ok){
        setIsContentSessionChange(true)
        setIsUpdating(false)
        $(`#sessionEditModal_${ID}`).modal('hide')
      }
    }

    useEffect(() => {
      if((contentsessionValue?.isBoolean || contentsessionValue?.isText)){
        $(`#sessionEditModal_${ID}`).modal('show')
      }
    }, [contentsessionValue])

  if (width < lg) {
    return (
      <div className="contentSessionCard-container">
        {(contentsessionValue?.isBoolean || contentsessionValue?.isText) &&
        showEdit ? (
          <SessionEditModal
            contentsessionValue={contentsessionValue}
            setContentsessionValue={setContentsessionValue}
            updateContentsession={updateContentsession}
            isUpdating={isUpdating}
            contentSessionSettingsField={contentSessionSettingsField}
            ID={ID}
          />
        ) : null}
        <div
          className="row"
          style={{
            marginLeft: ".5rem",
            width: "100%",
          }}
        >
          <p className="mbox-title-large">Title&nbsp;:&nbsp;</p>
          <p className="mbox-title-medium"> {title}</p>
        </div>
        <div
          className="row"
          style={{
            width: "100%",
            marginLeft: ".5rem",
          }}
        >
          <p className="mbox-title-large">ID&nbsp;:&nbsp;</p>
          <p className="mbox-title-medium"> {ID}</p>
        </div>
        <div className="row" style={{ width: "100%" }}>
          <div className="col" style={{ borderLeft: "3px solid #72AEF7" }}>
            <p className="mbox-title-large">START</p>
            <p className="mbox-title-medium">
              {dateFormat.format(new Date(startDate))}
            </p>
          </div>
          <div className="col" style={{ borderLeft: "3px solid #BA1A1A" }}>
            <p className="mbox-title-large">END</p>
            <p className="mbox-title-medium">
              {dateFormat.format(new Date(endDate))}
            </p>
          </div>
        </div>
        <div className="contentSessionCard-link-container">
          {showEdit &&
            contentSessionSettingsField.length !== 0 && (
              <button
                className=" btn mBoxListCard-link mbox-captions mr-2"
                style={{
                  borderRadius: "8px",
                  padding: "5px",
                  minWidth: "50px",
                }}
                onClick={getContentsession}
              >
                View Settings
              </button>
            )}
          <button
            className="col-1 contentSessionCard-link-container"
            style={{
              background: "transparent",
              border: "none",
              outline: "none",
              display:"flex",
              justifyContent:"center"
            }}
            onClick={onClick}
          >
            <MyLink
              to={link}
              className="contentSessionCard-link"
              style={{ textDecoration: "none" }}
            >
              <i
                className="fas fa-long-arrow-alt-right"
                style={{ fontSize: "15px" }}
              ></i>
            </MyLink>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="row contentSessionCard-container">
      {(contentsessionValue?.isBoolean || contentsessionValue?.isText) && showEdit ? (
            <SessionEditModal
            contentsessionValue={contentsessionValue}
            setContentsessionValue={setContentsessionValue}
            updateContentsession={updateContentsession}
            isUpdating={isUpdating}
            ID={ID}
            />
          ) : null}
      {startDate && (
        <div
          className="col-1 mbox-title-medium"
          style={{ borderLeft: "3px solid #72AEF7" }}
        >
          <p>{dateFormat.format(new Date(startDate))}</p>
        </div>
      )}

      {endDate && (
        <div
          className="col-1 mbox-title-medium"
          style={{ borderLeft: "3px solid #BA1A1A" }}
        >
          <p>{dateFormat.format(new Date(endDate))}</p>
        </div>
      )}

      <div className="col-7 mbox-title-large">
        <p>{title}</p>
      </div>
      <div className="col-1 mbox-title-medium" style={{ paddingLeft: 0 }}>
        <p>{ID}</p>
      </div>

      {showEdit && contentSessionSettingsField.length !== 0 ? <button
        className="col-1 btn mBoxListCard-link mbox-captions"
        style={{borderRadius:"8px", padding:"5px", width:"20%"}}
        onClick={getContentsession}
      >
        View Settings
      </button> :<div className="col-1"></div>}

      <button
        className="col-1 contentSessionCard-link-container"
        style={{ background: "transparent", border: "none", outline: "none" }}
        onClick={onClick}
      >
        <MyLink
          to={link}
          className="contentSessionCard-link"
          style={{ textDecoration: "none" }}
        >
          <i
            className="fas fa-long-arrow-alt-right"
            style={{ fontSize: "18px" }}
          ></i>
        </MyLink>
      </button>
    </div>
  );
};

export default ContentSessionCard;
