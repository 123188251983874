import React, { useEffect, useState } from "react";
import _ from 'lodash'
import "./eventsList.css";
import routes from "./../../config/routes.json";
import ContentSessionCard from "../../components/contentSessionCard/ContentSessionCard";
import Header from "../../components/header/Header";
import LoadingSpinner from "../../components/LoadingSpinner";
import useApiHook from "../../hooks/useApiHook";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import BootstrapScreenSize from "../../utils/BootstrapSceensize";
import { removeKeys, store } from "../../utils/SetLocalstorage";
import {
  LocalstorageContentsessionConfig,
  LocalstorageEventConfig,
  LocalstorageMBoxConfig,
} from "../../config/LocalstorageConfig";
import { useContext } from "react";
import MBoxContext from "../../context/MBoxContext";
import Searchbar from "../../components/searchbar/Searchbar";
import Page from "../../components/common/Page";
import { doSearch } from "../../utils/AppHelpers";
import Alert from "../../components/common/Alert";
import { getBaseUrl } from "../../utils/QueryString";

const EventsList = () => {
  const [searchInput, setSearchInput] = useState("");

  const { baseUrl, fieldsName, pageTitle } = useContext(MBoxContext);
  const url = `https://${getBaseUrl()}/v1/apps/${localStorage.getItem(
    "app_id"
  )}/events.json?per_page=9999&is_course=-1`;
  const { data, isLoading } = useApiHook(url);
  const [resultData, setResultData] = useState(null);
  const { width } = useWindowDimensions();
  const { lg } = BootstrapScreenSize;
  const {
    CONTENTSESSION_ID,
    CONTENTSESSION_TITLE,
    CONTENTSESSION_STARTSAT,
    CONTENTSESSION_ENDSAT,
    CONTENTSESSION
  } = LocalstorageContentsessionConfig;
  const { MBOX_ID, MBOX_NAME, ROOM_ID, ROOM_TITLE } = LocalstorageMBoxConfig;
  const { EVENT_ID, EVENT_TITLE, EVENT_STARTSAT, EVENT_ENDSAT } =
    LocalstorageEventConfig;

  useEffect(() => {
    setResultData(doSearch(data, searchInput));
  }, [searchInput])
    
  useEffect(() => {
    removeKeys([
      MBOX_ID,
      MBOX_NAME,
      ROOM_ID,
      ROOM_TITLE,
      CONTENTSESSION_ID,
      CONTENTSESSION_TITLE,
      CONTENTSESSION_STARTSAT,
      CONTENTSESSION_ENDSAT,
      CONTENTSESSION
    ]);
    setResultData(data);
  }, [data]);

  const selectEvent = (singleEvent) => {
    store(EVENT_ID, singleEvent.id);
    store(EVENT_TITLE, singleEvent.title);
    store(EVENT_STARTSAT, singleEvent.starts_at);
    store(EVENT_ENDSAT, singleEvent.ends_at);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Page name="events-page">
      {/* <Header /> */}
      <div className="eventsList-container">
        <div className="eventsList-title-container">
          <p className="mbox-headline-large">{pageTitle.eventPage}</p>
        </div>
        <div>
          <Searchbar
            setInput={setSearchInput}
            searchInput={searchInput}
            searchbarPlaceholder="Please Enter Title or Event Id ..."
          />
        </div>
        {width > lg && (
          <div className="row eventsList-formTitle-container mbox-headline-medium">
            <div className="col-1 eventsList-title-item-container">
              Event From
            </div>
            <div className="col-1 eventsList-title-item-container">
              Event To
            </div>
            <div className="col-7 eventsList-title-item-container">
              Event Name
            </div>
            <div className="col-1 eventsList-title-item-container">
              {fieldsName.ID}
            </div>
          </div>
        )}

        {!resultData?.length && (
          <Alert type="info" size="m">
            No Results...
          </Alert>
        )}

        {resultData?.map((singleEvent, index) => (
          <ContentSessionCard
            key={index}
            startDate={singleEvent?.starts_at}
            endDate={singleEvent?.ends_at}
            title={singleEvent?.title}
            ID={singleEvent?.id}
            link={routes.SERVICES}
            onClick={() => selectEvent(singleEvent)}
          />
        ))}
      </div>
    </Page>
  );
};

export default EventsList;
