import React, { useEffect, useState } from "react";
import { useContext } from "react";
import Alert from "../../components/common/Alert";
import Page from "../../components/common/Page";
import LoadingSpinner from "../../components/LoadingSpinner";
import MBoxListCard from "../../components/mboxListCard/MBoxListCard";
import {
  LocalstorageContentsessionConfig,
  LocalstorageMBoxConfig,
} from "../../config/LocalstorageConfig";
import MBoxContext from "../../context/MBoxContext";
import useApiHook from "../../hooks/useApiHook";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import BootstrapScreenSize from "../../utils/BootstrapSceensize";
import { removeKeys, store } from "../../utils/SetLocalstorage";
import "./mBoxes.css";
import { getBaseUrl } from "../../utils/QueryString";

const MBoxes = () => {
  const {pageTitle,fieldsName, baseUrl} = useContext(MBoxContext)
  const url = `https://${getBaseUrl()}/onsite/m_boxes.json?event_id=${localStorage.getItem(
    "event_id"
  )}&per_page=9999`;

  const { data, isLoading } = useApiHook(url);
  const { width } = useWindowDimensions();
  const { sm } = BootstrapScreenSize;
  const {
    CONTENTSESSION_ID,
    CONTENTSESSION_TITLE,
    CONTENTSESSION_STARTSAT,
    CONTENTSESSION_ENDSAT,
    CONTENTSESSION
  } = LocalstorageContentsessionConfig;
  const { MBOX_ID, MBOX_NAME, ROOM_ID, ROOM_TITLE } = LocalstorageMBoxConfig;

  const [isRoomChange, setIsRoomChange] = useState(false)

  useEffect(() => {
    if(isRoomChange){
      setIsRoomChange(false);
      window.location.reload(true);
    }
    
    removeKeys([
      CONTENTSESSION_ID,
      CONTENTSESSION_TITLE,
      CONTENTSESSION_STARTSAT,
      CONTENTSESSION_ENDSAT,
      CONTENTSESSION
    ]);
  }, [isRoomChange]);


  const selectMBox = (list, room) => {
    store(MBOX_ID, list.id);
    store(MBOX_NAME, list.name);
    store(ROOM_ID, room.id);
    store(ROOM_TITLE, room.title);
  };

  if (isLoading || isRoomChange) {
    return (
      <Page>
        <LoadingSpinner />
      </Page>
    );
  }

  const areBoxesAvailable = data?.length > 0;

  return (
    <Page name="mboxes-page">
      <div className="mBoxes-container">
        <div className="mBoxes-title-container">
          <p className="mbox-headline-large">{pageTitle.mBoxPage}</p>
        </div>
        {width > sm && areBoxesAvailable &&(
          <div className="row mBoxes-formTitle-container">
            <div className="col-5 mBoxes-title-item-container">
              {fieldsName.title}
            </div>
            <div className="col-3 mBoxes-title-item-container">
              {fieldsName.room_title}
            </div>
            <div className="col-2 mBoxes-title-item-container">
            Box ID
            </div>
          </div>
        )}
        {areBoxesAvailable ? (
          <div>
            {data?.map((mBoxItem) =>{
            const room = mBoxItem?.rooms?.length >  0 ? mBoxItem?.rooms[0] : null;
             return <MBoxListCard
              key={mBoxItem?.id}
              title={mBoxItem?.name}
              roomTitle={room?.title}
              roomID={room?.id}
              ID={mBoxItem?.id}
              link="/contentsessions"
              onClick={() => selectMBox(mBoxItem, room)}
              setIsRoomChange={setIsRoomChange}
           />}
            )}
          </div>
        ) : (
          <Alert type="info" size="m">No Results...</Alert>
        )}
      </div>
    </Page>
  );
};

export default MBoxes;
