import { useLocation, Link } from "react-router-dom";

const MyLink = ({ children, to, ...rest }) => {
  const location = useLocation();
  return (
    <Link to={`${to}${location.search}`} {...rest}>
      {children}
    </Link>
  );
};

export default MyLink;
