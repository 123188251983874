import "./AppsConfig.scss";
import React, { useLayoutEffect, useState, useContext, useRef } from "react";
import ReactDiffViewer from "react-diff-viewer-continued";
import { withRouter } from "react-router";
import {Modal} from "react-vm-component-library";
import _ from "lodash";
import { toast } from "react-toastify";
import ConfigForm from "./../../components/rjsf/forms/ConfigForm";
import routes from "./../../config/routes.json";
import { getIsAdmin } from "../../utils/QueryString";
import Page from "../../components/common/Page";
import { isNullOrWhitespace } from "../../utils/Validator";
import Message from "./../../components/common/Message";
import BackButton from "./../../components/common/BackButton";
import { get_translations } from "../../store/helper";
import { makeHttpCall } from "./../../utils/HttpHelpers";
import { getRndInteger, mergeDeepExistingv2, mergeDeepIncludeAllKeys, sortByKeys } from "../../utils/AppHelpers";
import { delay } from "../../utils/Tools";
import { printPretty } from "./../../utils/AppHelpers";
//import Modal from "./../../components/common/Modal";
import FullScreenMessage from "./../../components/common/FullScreenMessage";
import MBoxContext from "../../context/MBoxContext";
import Alert from './../../components/common/Alert';
import AddReactToolTip from './../../components/common/AddReactToolTip';



const ERROR_MESSAGE = "Config files not found!";

const AppsConfig = (props) => {
  const [loading, setLoading] = useState(false);
  const [configSavedCount, setConfigSavedCount] = useState(0);
  const [savingConfig, setSavingConfig] = useState(null);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(null);
  const [service, setService] = useState(null);
  const [appSettingExist, setAppSettingExist] = useState(null);

  const [formDataInit, setFormDataInit] = useState(null); //basis for Edit
  const formDataApiRef = useRef(null); //stored in API
  const formDataInitialS3Ref = useRef(null); //stored in S3

  const { servicesList, appText } = useContext(MBoxContext);

  const isQsAdmin = getIsAdmin();

  useLayoutEffect(() => console.log("service change"), [service]);

  useLayoutEffect(() => {
    console.log(props.location.state);
    (async () => {
      const serviceObj =
        props?.location?.state?.appKey &&
        servicesList[props.location.state.appKey];

      if (!serviceObj || !serviceObj?.jsonData || isNullOrWhitespace(serviceObj?.translation_key)) {
        setError({
          ok: false,
          loading: true,
          text: "You need to choose a service that has the appropriate settings.",
        });
        return;
      }
      const dynamicJsonResp = await makeHttpCall({
        url: `${serviceObj?.jsonData?.formData}?rnd=${getRndInteger(100, 100000)}`,
      });
      if (!dynamicJsonResp?.ok) {
        setError({ ok: false, loading: true, text: ERROR_MESSAGE });
        return;
      }

      setService(serviceObj);
      formDataInitialS3Ref.current = dynamicJsonResp?.data;

      const { eventId } = props.location.state;
      const resp = await get_translations({
        eventId,
        translation_key: serviceObj?.translation_key,
      });

      let appConfig = {};
      if (resp?.ok && resp?.data?.length > 0) {
        appConfig = JSON.parse(
          resp?.data?.[0]?.translationkey?.translations?.[0].value
        );
        formDataApiRef.current = appConfig;
        setAppSettingExist(true);
        
      }
      setFormDataInit(
        //mergeExisting(formDataInitialS3Ref.current, appConfig)
        mergeDeepExistingv2(formDataInitialS3Ref.current, appConfig)
      );
    })();
    //session_id
  }, [props?.location?.state, servicesList, configSavedCount]);

  const onBackClick = () => {
    props.history.push({
      pathname: routes.SERVICES,
      search: window.location.search // Preserving the current query string
    });
  };
  const getToCompare = (pSrc) => {
    return printPretty(sortByKeys(_.cloneDeep(pSrc)));
  };

  const onSaveConfig = (pNewConfig) => {
    setModal({ data: pNewConfig });
  };
  const onModalConfirmedChanges = async () => {
    //Save Proccess Image: https://d3klq1qh6r64da.cloudfront.net/m-box/img/mergeProccessAppsConfig.png
    setSavingConfig(true);
    //1.) merge apiConfig && localChanges
    //const apiPushConfig = _.merge(_.cloneDeep(formDataApiRef.current), modal?.data);
    const apiPushConfig = mergeDeepIncludeAllKeys(_.cloneDeep(formDataApiRef.current), modal?.data);
    console.log(apiPushConfig);
    //2.) push/update api/DB
    const { eventId } = props.location.state;
    const resp = await get_translations({
      eventId,
      translation_key: service?.translation_key,
      value: apiPushConfig,
    });
    setModal(null);
    await delay(3 * 1000);
    setSavingConfig(false);
    //3.) go to Services page
    // onBackClick();
    toast(
      "Save successful! Your changes have been successfully saved.",
      {
        theme: "colored",
        type: "success",
        autoClose: 3300,
      }
    );
    //NOTE: execute useEffect again
    setConfigSavedCount(prev=>prev+1);
  };

  if (error?.loading)
    return (
      <Page>
        <Message onBackBtnClick={onBackClick}>
          <div className="mbox-message__error">{error?.text}</div>
        </Message>
      </Page>
    );
  return (
    <Page name="apps-config">
      <AddReactToolTip />
      {savingConfig && <FullScreenMessage show text="saving..." />}
      {modal && (
        <Modal
          title={"Confirmation"}
          size={isQsAdmin ? "fullscreen":"md"}
          txtBtnNo={"Cancel"}
          txtBtnYes={isQsAdmin ? "Confirm changes (no undo possible)":"Confirm"}
          onYesClick={onModalConfirmedChanges}
          onClose={() => setModal(null)}

        >
          
          <div className="body my-4">
            <div>{appText?.txtConfirmChanges}</div>
           {isQsAdmin &&  <ReactDiffViewer
              oldValue={getToCompare(formDataInit)}
              newValue={getToCompare(modal?.data)}
              splitView={true}
            />}
          </div>
        </Modal>
      )}
      <div
        className="apps-config__container"
      >
        <div className="my-1">
          <BackButton className="test" onClick={onBackClick} />
        </div>
        <p className="apps-config__heading mbox-headline-large">Settings for: <span>{service?.title}</span></p>
      </div>
      {error && (
        <Message showBackBtn={false}>
          <div className="mbox-message__error">
            <i className="fas fa-exclamation-triangle"></i>
            {error?.text || "Something went wrong."}
          </div>
        </Message>
      )}
       {!appSettingExist && formDataInit && (
        <Alert type="info" hideClose>
          Config settings doesn't exist. You can create one by
          clicking on Save Changes button.
        </Alert>
      )}
      {!error && service?.jsonData && (
        <ConfigForm
          jsonData={service?.jsonData}
          formDataInit={formDataInit}
          appKey={service?.translation_key}
          onSave={onSaveConfig}
          modalVisible={modal}
          onError={(pErrorText) => {
            setError({ ok: false, text: pErrorText });
          }}
        />
      )}
    </Page>
  );
};

export default withRouter(AppsConfig);
