import React, {useEffect, useState} from "react";
import { breadcrumbOptions } from "../../store/config";
import {Tooltip} from 'react-tooltip';

import "./Breadcrumbs.css";
import { get } from "../../utils/SetLocalstorage";
import { useLocation } from "react-router-dom";
import MyLink from "../common/MyLink";


const Breadcrumbs = () => {

    const [breadcrumbData, setBreadcrumbData] = useState()
    const [isMounted, setIsMounted] = useState(false);
    const location = useLocation();

    const initOptions = breadcrumbOptions.items

    useEffect(() => {
      setIsMounted(false)
    const filterOptionsIndex = initOptions.findIndex(singlePath => singlePath.to === location.pathname)
    
    const data = [];

    if(filterOptionsIndex === 0) {
        data.push(initOptions[0])
    }else{
        for(let i=0; i <= filterOptionsIndex; i++){
          data.push(initOptions[i]);
        }
    }
    setBreadcrumbData(data);
    setIsMounted(true)

    // return () => {setIsMounted(false); console.log("Unmounted")}
    }, [location.pathname]);

    return (
      <div>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb" style={{ backgroundColor: "transparent" }}>
            {breadcrumbData?.map((d, index) => (
              <li className="breadcrumb-item" key={index}>
                {!!d?.tooltips?.length && isMounted && (
                  <Tooltip
                    className="breadcrumb-tooltip-container"
                    anchorSelect={`#${d?.tooltipName}_${index}`}
                    place="bottom"
                  >
                    {d.tooltips.map((tooltip, index) => (
                      <div
                        className="breadcrumb-tooltip-container-item"
                        key={index}
                      >
                        <span className="breadcrumb-tooltip-container-item_title">
                          {tooltip.label}:&nbsp;
                        </span>
                        <span className="breadcrumb-tooltip-container-item_value">
                          {get(tooltip.store)}
                        </span>
                      </div>
                    ))}
                  </Tooltip>
                )}
                {index === breadcrumbData.length - 1 ? (
                  <span className="mbox-captions" style={{ color: "gray" }}>
                    {d?.label}
                  </span>
                ) : (
                  <MyLink
                    id={`${d?.tooltipName}_${index}`}
                    className="breadcrumb-item_link mbox-captions"
                    to={d?.to}
                  >
                    {d?.label}
                  </MyLink>
                )}
              </li>
            ))}
          </ol>
        </nav>
      </div>
    );
}

export default Breadcrumbs;