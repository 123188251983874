import "./services.scss";
import React from "react";
import _ from "lodash";
import routes from "./../../config/routes.json";
import CardWithIcon from "../../components/Cards/CardWithIcon";
import { useContext } from "react";
import MBoxContext from "../../context/MBoxContext";
import { get } from "../../utils/SetLocalstorage";
import {
  addParamsToBaseAppLink,
} from "./../../utils/AppHelpers";
import UserInfo from "./../../components/UserInfo";
import { withRouter } from "react-router";
import Page from "../../components/common/Page";
import Sublinks from "./Sublinks";


const Services = (props) => {
  const { pageTitle, servicesList } = useContext(MBoxContext);
  console.log(servicesList?.["MAttach"].show);

  const onAppConfigHandler = (appKey) => {
    props.history.push({
      pathname: routes.APPS_CONFIG,
      search: window.location.search, // Preserving the current query string
      state: { appKey, eventId },
    });
  };

  if (get("role") !== "m box admin" && get("role") !== "vm mbox admin") {
    return <UserInfo />;
  }
  const eventId = get("event_id");

  return (
    <Page name="services-page">
      <div className="servicesPage-title-container mbox-headline-large">
        <p>{pageTitle.servicePage}</p>
      </div>
      <div className="row servicesPage-container">
        {Object.keys(servicesList).map((key) => {
          const currServiceObj = servicesList[key];
          if (!currServiceObj?.show || currServiceObj?.skipEventLevel)
            return null;
          return (
            <CardWithIcon
              key={key}
              description={currServiceObj?.description}
              {...currServiceObj}
              onConfigClick={() => onAppConfigHandler(key)}
              link={addParamsToBaseAppLink(
                key,
                currServiceObj?.link,
                eventId,
                currServiceObj?.isUrlEncrypted
              )}
              showOpenInOtherTab={currServiceObj?.openInNewTab}
            >
              {currServiceObj?.sublinks?.length > 0 && (
                <Sublinks
                  propsArray={currServiceObj?.sublinks}
                  isUrlEncrypted={currServiceObj?.isUrlEncrypted}
                />
              )}
            </CardWithIcon>
          );
        })}
      </div>
    </Page>
  );
};

export default withRouter(Services);
