import { isNullOrUndefined } from "./Validator";

const getQSValue = (pQsKey) => {
  if (isNullOrUndefined(pQsKey)) return null;
  const lQsKey = pQsKey.toLowerCase();
  const lUrlParams = getUrlParamsCaseIS(); //NOTE: make it case insensitive
  let lQsValue = lUrlParams.get(lQsKey);

  return lQsValue;
};
//NOTE: make it case insensitive
const getUrlParamsCaseIS = () => {
  const qsParams = new URLSearchParams(window.location.search);
  let lUrlParams = new URLSearchParams();
  for (const [name, value] of qsParams) {
    lUrlParams.append(name.toLowerCase(), value);
  }
  return lUrlParams;
};

export const getBaseUrl = () => {
  //const urlParams = new URLSearchParams(window.location.search);
  if(getQSValue("base_url")?.includes("easd") || getQSValue("server")?.includes("easd")) return "easd-api-lb.virtual-meeting.net"
  return getQSValue("base_url") || "api-lb.virtual-meeting.net";
};

export const getPguid = () => getQSValue("pguid");
export const getEventId = () => getQSValue("event_id");
export const getRoomId = () => getQSValue("room_id");
export const getSessionId = () => getQSValue("session_id");
export const getIsAdmin= () => getQSValue("is_admin") === "1" ? true : false;

