import React from 'react';
import { isValidUrlCheck } from "../../utils/Validator";
import { convertUrlToEncrypt } from '../../utils/AppHelpers';
import MyLink from '../../components/common/MyLink';

const Sublinks = ({propsArray, isUrlEncrypted}) => {
  const checkLinksWithParams = (link) => {
    var pattern = /[^{\}]+(?=})/g;
    const findMatch = link.match(pattern);

    if (findMatch) {
      let newLink = "";

      for (let i = 0; i < findMatch.length; i++) {
        if (i === 0) {
          newLink = link.replace(
            `{${findMatch[i]}}`,
            localStorage?.getItem(`${findMatch[i]}`)
          );
        } else {
          newLink = newLink.replace(
            `{${findMatch[i]}}`,
            localStorage?.getItem(`${findMatch[i]}`)
          );
        }
      }
      return newLink;
    }
    return link;
  };
  if (propsArray.length > 0) {
    
    return (
      <div className="servicesPage-children-container">
        {propsArray.map((child, index) => (
          <div key={index}>
            {/* {console.log("qwert sublinks", propsArray)} */}
            {isValidUrlCheck(child.link) ? (
              <a
                href={
                  isUrlEncrypted
                    ? convertUrlToEncrypt(checkLinksWithParams(child.link))
                    : checkLinksWithParams(child.link)
                }
                className="servicesPage-children-link mbox-captions"
              >
                {child?.title}{" "}
              </a>
            ) : (
              <MyLink
                to={child.link}
                className="servicesPage-children-link mbox-caption"
              >
                {child?.title}{" "}
              </MyLink>
            )}
          </div>
        ))}
      </div>
    );
  }
  return null
};

export default Sublinks;
