import { useEffect, useState } from "react";
import useQuery from "../../hooks/useQuery";
import { getBaseUrl } from "./../../utils/QueryString";
import { remove, store } from "../../utils/SetLocalstorage";
import { makeHttpCall } from "../../utils/HttpHelpers";
import { inflate64 } from "../../utils/PakoHelper";

const Oca = ({onSetAppId}) => {
  //localhost:3000/oca?login_id=6985726&access_token=c42f0174c496c4081a2a48708929f3dc
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [loginDetails, setLoginDetails] = useState({
    loginId:null,
    accessToken:null
  })

  let query = useQuery();
  // const url = `https://${getBaseUrl()}/v1/logins/${loginDetails?.loginId}.json?include_user=1&mode=Admin&access_token=${loginDetails?.accessToken}`;

  const getUserCredentials = async (url) => {
    const apiRes = await makeHttpCall({
      url: url,
      method: "GET",
      isVm: true,
    });
    if (apiRes?.ok) {
      let data = apiRes?.data
      let adminUser = data?.roles?.find(
        (role) => (role.title === "m box admin" || role.title === "vm mbox admin")
      );
      if (adminUser) {
        store("role", "vm mbox admin");
        store(
          "user_name",
          `${data?.user?.firstname} ${data?.user?.surname}`
        );
        store("app_id", `${data?.app_id}`);
        if(onSetAppId) onSetAppId(data?.app_id);
        store("access_token", loginDetails?.accessToken)
        store("expires_at", data?.expires_at)
        remove("mboxSeletah.user");
        window.location.href = `/events?base_url=${getBaseUrl()}`;
        setIsUserAdmin(true);
      } else {
        setIsUserAdmin(false);
      }
    }
  };

  useEffect(() => {

    if(loginDetails.loginId || loginDetails.accessToken){
      const url = `https://${getBaseUrl()}/v1/logins/${loginDetails?.loginId}.json?include_user=1&mode=Admin&access_token=${loginDetails?.accessToken}`
      getUserCredentials(url);
    }
    
  }, [loginDetails, loginDetails.loginId]);

  useEffect(() => {
    if(!!query.get("token")){
      const inflateValue = JSON.parse(inflate64(query.get("token")))
      const login_id = inflateValue?.login_id
      const access_token = inflateValue?.access_token
      setLoginDetails({
        ...loginDetails,
        loginId: login_id,
        accessToken: access_token
      });
    }else{
      setLoginDetails({
        ...loginDetails,
        loginId: query.get("login_id"),
        accessToken: query.get("access_token"),
      });
    }
  }, [])



  if (!isUserAdmin || !loginDetails.loginId || !loginDetails.accessToken) {
    return (
      <div>
        <h5 style={{ color: "red" }}>
          You are not the admin or login credentials are incorrect, please contact us
        </h5>
      </div>
    );
  }
};

export default Oca;
