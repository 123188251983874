/* eslint-disable no-undef */
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import BootstrapScreenSize from "../../utils/BootstrapSceensize";
import "./mBoxListCard.css";
import { Link } from "react-router-dom";
import ReasigneModal from "../reassigneModal/ReasigneModal";
import { makeHttpCall } from "../../utils/HttpHelpers";
import MBoxContext from "../../context/MBoxContext";
import MyLink from "../common/MyLink";
import { getBaseUrl } from "../../utils/QueryString";

const MBoxListCard = (props) => {
  const { title, roomTitle, roomID, ID, link, onClick, setIsRoomChange } =
    props;
  const { width } = useWindowDimensions();
  const { sm } = BootstrapScreenSize;
  const { baseUrl } = useContext(MBoxContext);
  const ACCESSTOKEN = localStorage.getItem("access_token");
  const EVENTID = localStorage.getItem("event_id");

  const [allRooms, setAllRooms] = useState([]);
  const [roomChangedValue, setRoomChangedValue] = useState({
    id: null,
    title: "",
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const getRoomsUrl = `https://${getBaseUrl()}/v1/rooms.json?per_page=999&event_id=${EVENTID}`;

  const updateMBoxRoomUrl = `https://${getBaseUrl()}/onsite/m_boxes/${ID}.json`;

  const getAllRooms = async () => {
    const allRooms = await makeHttpCall({
      url: getRoomsUrl,
      method: "GET",
      isVm: true,
    });

    if (allRooms?.ok) {
      if (allRooms?.data?.length > 0) {
        setAllRooms(allRooms.data);
        $(`#reasigneModal_${ID}`).modal("show");
      } else{
        toast(
          "There are no rooms in this event, Tipp: Maybe you should create first few rooms.",
          {
            theme: "colored",
            type: "warning",
            autoClose: 7000,
          }
        );
      }
    }
  };

  const updateRoom = async () => {
    setIsUpdating(true);
    const params = {
      id: ID,
      access_token: ACCESSTOKEN,
      event_id: EVENTID,
      room_id: roomChangedValue.id,
    };
    const update = await makeHttpCall({
      url: updateMBoxRoomUrl,
      method: "PUT",
      bodyJson: JSON.stringify(params),
      isVm: true,
    });

    if (update?.ok) {
      setIsRoomChange(true);
      setIsUpdating(false);
      $("#reasigneModal").modal("hide");
    }
  };

  // useEffect(() => {
  //   if (allRooms.length > 0) {
  //     $("#reasigneModal").modal("show");
  //   }
  // }, [allRooms]);

  const roomTitleCol = roomTitle ? (
    `(${roomID}) ${roomTitle}`
  ) : (
    <span className="text-danger">
      <i className="fas fa-exclamation-triangle mr-2"></i>No assigment is made.
    </span>
  );

  if (width < sm) {
    return (
      <div className="row  mBoxListCard-container" style={{ color: "#fcfcfc" }}>
        {allRooms?.length ? (
          <ReasigneModal
            originRoom={`${roomTitle && roomID ? `${roomTitle} (${roomID})`:`No room assign`}`}
            id={ID}
            roomOptions={allRooms}
            roomChangedValue={roomChangedValue}
            setRoomChangedValue={setRoomChangedValue}
            updateRoom={updateRoom}
            isUpdating={isUpdating}
          />
        ) : null}
        <div className="row mbox-list-row-container">
          <div className="col mbox-list-item-title-container">Title : </div>
          <div className="col">{title}</div>
        </div>
        <div className="row mbox-list-row-container">
          <div className="col mbox-list-item-title-container">Room Title : </div>
          <div className="col">{roomTitleCol}</div>
        </div>
        <div className="row mbox-list-row-container">
          <div className="col mbox-list-item-title-container">roomID : </div>
          <div className="col">{roomTitleCol}</div>
        </div>
        <div className="row mbox-list-row-container">
          <div className="col mbox-list-item-title-container">ID : </div>
          <div className="col">{ID}</div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <button
            type="button"
            className="btn mBoxListCard-link mbox-captions"
            style={{ borderRadius: "8px", padding: "5px" }}
            data-toggle="modal"
            onClick={getAllRooms}
          >
           <i
                className="fas fa-cog btn-gear"
                title="Assing Room"
              ></i>
          </button>
          <button
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: ".5rem",
              background: "transparent",
              outline: "none",
              border: "none",
            }}
            onClick={onClick}
          >
            <MyLink
              to={link}
              className="mBoxListCard-link"
              style={{ textDecoration: "none" }}
            >
              <i
                className="fas fa-long-arrow-alt-right"
                style={{ fontSize: "15px" }}
              ></i>
            </MyLink>
          </button>
        </div>
      </div>
    );
  }


  return (
    <div className="row mBoxListCard-container">
      <ReasigneModal
        originRoom={`${
          roomTitle && roomID ? `${roomTitle} (${roomID})` : `No room assign`
        }`}
        id={ID}
        roomOptions={allRooms}
        roomChangedValue={roomChangedValue}
        setRoomChangedValue={setRoomChangedValue}
        updateRoom={updateRoom}
        isUpdating={isUpdating}
      />
      <div className="col-5">
        <span>{title}</span>
      </div>
      <div className="col-3 " style={{ paddingLeft: 0 }}>
        {roomTitleCol}
      </div>

      <div className="col-2 " style={{ paddingLeft: 0 }}>
        <span>{ID}</span>
      </div>

      {/* <button
        type="button"
        // className="btn col-2 mBoxListCard-link mbox-captions"
        style={{ borderRadius: "8px", padding: "5px", backgroundColor:"transparent", border:"none" }}
        data-toggle="modal"
        onClick={getAllRooms}
      > */}
        <i className="fas fa-cog btn-gear col-1" title="Assing Room" data-toggle="modal" onClick={getAllRooms}></i>
      {/* </button> */}
      <button
        className=" mBoxListCard-link-container col-1"
        onClick={onClick}
        style={{ background: "transparent", border: "none", outline: "none" }}
      >
        <MyLink
          to={link}
          className="mBoxListCard-link"
          style={{ textDecoration: "none" }}
        >
          <i
            className="fas fa-long-arrow-alt-right"
            style={{ fontSize: "15px" }}
          ></i>
        </MyLink>
      </button>
    </div>
  );
};

export default MBoxListCard;
