import "./ConfigForm.scss";
import React, { useState, useLayoutEffect, useRef } from "react";
import Form from "@rjsf/core";
//import Form from '@rjsf/bootstrap-4';
//import Form from '@rjsf/bootstrap-4';
//import { RJSFSchema, RegistryWidgetsType } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import _ from "lodash";
import useWindowDimensions from './../../../hooks/useWindowDimensions';
//import formData from "./../../jsonFilesQna/formData.json"
import CustomRange from "../customWidgets/CustomRange";
import CustomColor from "./../customWidgets/CustomColor";
import CustomString from "./../customWidgets/CustomString";
import SelectTimeZones from "./../customWidgets/SelectTimeZones";
import { makeHttpCall } from './../../../utils/HttpHelpers';
import CustomCheckbox from './../customWidgets/CustomCheckbox';
import { getRndInteger, mergeDeepIncludeAllKeys } from "../../../utils/AppHelpers";
import CheckboxField from './../customFields/customCheckboxField';
import AppPreview from "./AppPreview";


const ERROR_MESSAGE = "Config files not found!";
const SUPPORTED_PREVIEW_APPS =["signagev3.mlook.config", "signagev3.airport.config","onsite.qna.config"];

const ConfigForm = ({jsonData, formDataInit, onSave, onError, appKey, modalVisible,...rest}) => {
  //const uiSchema = require("../jsonFiles/uiSchema.json");
  const [formError, setFormError] = useState(null);
  const refData = useRef(null);

  const [formData, setFormData] = useState(null);
  const [editData, setEditData] = useState(null);
  const [schema, setScheme] = useState(null);
  const [uiSchema, setUiSchema] = useState(null);
  const [submitData, setSubmitData] = useState(null);
  const [resetCounter, setResetCounter] = useState(0);
  const { width } = useWindowDimensions();

  useLayoutEffect(() => {
    (async () => {
      if (!jsonData) return;
      if(modalVisible) return;
      let dynamicJsonResp = null;
      
      dynamicJsonResp = await makeHttpCall({ url: `${jsonData?.schema}?rnd=${getRndInteger(100, 100000)}` });
      if (!dynamicJsonResp?.ok) {
        onError(ERROR_MESSAGE);
        return;
      }
      let schemaInit = dynamicJsonResp?.data;

      //const formDataInitialize = _.merge( _.cloneDeep(formDataInit), refData.current);
      const formDataInitialize = mergeDeepIncludeAllKeys( _.cloneDeep(formDataInit), refData.current);
      

      dynamicJsonResp = await makeHttpCall({ url: `${jsonData?.uiSchema}?rnd=${getRndInteger(100, 100000)}` });
      if (!dynamicJsonResp?.ok) {
        onError(ERROR_MESSAGE);
        return;
      }
      let uiSchemaInit = dynamicJsonResp?.data;

      const schemaTmp = uiSchemaInit || {};
      if (formDataInitialize?.colors) schemaTmp["colors"] = {};
      if (formDataInitialize?.roomsConfig) {
        schemaTmp["roomsConfig"] = {};
        schemaTmp["roomsConfig"]["text"] = {};
      }
      if (!schemaInit?.properties?.colors && appKey !== "faculty.hd-designer.config") {
        schemaInit.properties.colors = {
          type: "object",
          properties: {},
        };
      }

      for (const key in formDataInitialize?.colors) {
        schemaTmp["colors"][key] = {
          //"ui:widget": "color",
          "ui:widget": "customColor",
        };
      }
      
      for (const key in formDataInitialize?.mattachconfig?.colors) {
        schemaTmp["mattachconfig"]["colors"][key] = {
          //"ui:widget": "color",
          "ui:widget": "customColor",
        };
      }
      for (const key in formDataInitialize?.roomsConfig?.text) {
        schemaTmp["roomsConfig"]["text"][key] = {
          //"ui:widget": "color",
          "ui:widget": "customString",
        };
      }
      
      setScheme(schemaInit);
      setUiSchema({ ...schemaTmp });
      if(formDataInit) setFormData({ ...formDataInitialize});
      //console.log("once ", jsonData);
      //console.log(formDataInit, refData.current);
    })();
  }, [jsonData, onError, formDataInit, resetCounter, modalVisible]);

  const onReset =()=>{
    refData.current = null;
    setResetCounter(resetCounter + 1)
  };

  const customWidgets = {
    customRange: CustomRange,
    customString: CustomString,
    customColor: CustomColor,
    selectTimeZones: SelectTimeZones,
    text:CustomString,
    customCheckbox:CustomCheckbox
  };

  const fields = {
    checkboxField: CheckboxField,
    BooleanField:CheckboxField
  };

  const onSubmit =(props)=>{
      console.log(props);
      setSubmitData(props?.formData)
      setFormData((prev)=>{return {...prev, ...props?.formData}})
      if(onSave) onSave(props?.formData);

      setFormError(null);
  };

  const isAppPreviewVisible = width > 1200 && SUPPORTED_PREVIEW_APPS.includes(appKey);
  return (
    <div className="test-form-container">
      <div className="me-form-container">
        {schema && uiSchema && formData && (
          <Form
            className="me-form-config"
            schema={schema}
            formData={formData}
            validator={validator}
            uiSchema={uiSchema}
            onChange={(pData) => {
              //console.log(pData);
              //console.log(formData)
              refData.current = { ...pData?.formData };
              //setEditData(_.cloneDeep(pData?.formData))
              setFormData(pData?.formData)
              //console.log("onChange")
            }}
            onSubmit={onSubmit}
            onError={(e) => {
              console.log("error:", e);
              setFormError(e);
            }}
            widgets={customWidgets}
            fields={fields}
          >
            <div className="me-form-config__footer">
              <button
                type="reset"
                className="btn me-btn me-btn-outline mr-auto jme-disabled1"
                onClick={onReset}
              >
                <i className="fas fa-history"></i>
                Reset to default
              </button>
              {/* <button type="reset" className="btn me-btn me-btn-outline mr-2" onClick={onCloseAppClick}>
              <i className="fas fa-times-circle"></i>
                Close
              </button> */}
              <button type="submit" className="btn me-btn me-btn me-btn-submit">
                <i className="fas fa-cloud-upload-alt"></i>
                Save changes
              </button>
            </div>
          </Form>
        )}
        {formError && (
          <div className="error-summary">
            {formError?.map((c) => (
              <div>* {c?.stack}</div>
            ))}
          </div>
        )}
      </div>
      {isAppPreviewVisible && <AppPreview data={formData} appKey={appKey}/>}
    </div>
  );
};

export default ConfigForm;
